import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GalleryLayout from "./galleryLayout"

function Decorations() {
  return (
    <GalleryLayout title="Our Decorations">
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088133/sets/set-1_tuncyk.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088137/sets/set-2_agiszm.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088141/sets/set-3_vsv6qb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088145/sets/set-4_b5slw8.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088149/sets/set-5_sufomn.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088153/sets/set-6_rp2qmo.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088157/sets/set-7_tpd8ex.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088161/sets/set-8_vu6sxb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088165/sets/set-9_fpylni.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088169/sets/set-10_lczw3e.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088173/sets/set-11_bicpc3.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088177/sets/set-12_bqu3x7.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088181/sets/set-13_blsqzs.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088185/sets/set-14_bimkqo.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088189/sets/set-15_gwasvp.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088193/sets/set-16_awyjnh.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088197/sets/set-17_g169q7.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088201/sets/set-18_kdxcyh.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088205/sets/set-19_rwvyba.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088209/sets/set-20_upqsrt.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088213/sets/set-21_dmhsw8.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088217/sets/set-22_reolkb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088222/sets/set-23_ppea4t.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088226/sets/set-24_daikpl.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088230/sets/set-25_bavuvt.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088234/sets/set-26_b2qo2c.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088238/sets/set-27_uux2va.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088242/sets/set-28_fkhphb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088246/sets/set-29_kpuxxp.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088250/sets/set-30_xv9xnz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088254/sets/set-31_f0blnr.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088258/sets/set-32_nam9rz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088262/sets/set-33_jnzlq4.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088266/sets/set-34_mfxymf.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088270/sets/set-35_nuh7nt.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088274/sets/set-36_ladaib.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088278/sets/set-37_lanfgi.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088282/sets/set-38_se9pks.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088286/sets/set-39_bq324k.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088290/sets/set-40_jtjjol.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088294/sets/set-41_xbxmpb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088298/sets/set-42_twabco.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088302/sets/set-43_kymcv0.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088306/sets/set-44_j59ecc.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088310/sets/set-45_poixim.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088314/sets/set-46_w0pizz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088318/sets/set-47_tpzv7t.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088322/sets/set-48_y13sgt.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088326/sets/set-49_wxqiat.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088330/sets/set-50_infsaq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088334/sets/set-51_vo2qvz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088338/sets/set-52_wj5eja.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088342/sets/set-53_f47zqg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088346/sets/set-54_g2yd6f.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088350/sets/set-55_dmnrin.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088354/sets/set-56_gzwtnw.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088359/sets/set-57_zrpt1b.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088362/sets/set-58_jj7loq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088366/sets/set-59_sgleto.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088370/sets/set-60_n5jupg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088374/sets/set-61_z0uzjh.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088378/sets/set-62_dzcef2.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088382/sets/set-63_zdoo3l.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088386/sets/set-64_felspb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088390/sets/set-65_mlrq3e.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088394/sets/set-66_a4is6c.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088398/sets/set-67_peijjg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088402/sets/set-68_hhkgkn.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088406/sets/set-69_u1q2f9.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088410/sets/set-70_h3mnk6.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088414/sets/set-71_eli5dm.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088418/sets/set-72_dmavp3.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088422/sets/set-73_gglcym.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088426/sets/set-74_d0ca7i.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088430/sets/set-75_pjp10n.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088434/sets/set-76_tdpqry.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088439/sets/set-77_bxql4d.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088442/sets/set-78_srpsze.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088447/sets/set-79_fglz5w.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088451/sets/set-80_ccixmz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088455/sets/set-81_wtlhhq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088459/sets/set-82_akajsc.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088463/sets/set-83_wwider.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088467/sets/set-84_ye4xbq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088471/sets/set-85_sgpgal.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088475/sets/set-86_t3tasf.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088479/sets/set-87_ovkkie.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088483/sets/set-88_kllru6.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088487/sets/set-89_hr3twg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088491/sets/set-90_ftdbr8.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088495/sets/set-91_ibbul8.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088499/sets/set-92_uo6ayn.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088503/sets/set-93_jwv4qy.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088507/sets/set-94_el98tg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088511/sets/set-95_ri5ivs.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088515/sets/set-96_z5vmzj.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088519/sets/set-97_h2galz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088523/sets/set-98_vvqnrr.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088527/sets/set-99_klylv8.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088531/sets/set-100_uznxkm.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088535/sets/set-101_pvruft.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088539/sets/set-102_bqbi0w.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088543/sets/set-103_b2re4v.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088547/sets/set-104_ruzxzz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088551/sets/set-105_zymapc.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088555/sets/set-106_fwesyp.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088559/sets/set-107_i9v2lo.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088563/sets/set-108_inheka.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088567/sets/set-109_ao9spk.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088571/sets/set-110_ydonoa.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088575/sets/set-111_irlht1.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088587/sets/set-114_oz2rmn.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088628/sets/set-125_uhlbtn.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088631/sets/set-126_rvvhv1.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088636/sets/set-127_enu9i7.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088640/sets/set-128_yhztht.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088644/sets/set-129_c0wbkq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088648/sets/set-130_ulh0xz.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088652/sets/set-131_iridbq.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088656/sets/set-132_lmy52c.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088660/sets/set-133_ib7ep6.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088664/sets/set-134_r5bh51.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088669/sets/set-135_mqsrrf.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088677/sets/set-136_kzazds.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088682/sets/set-137_q67ygg.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088689/sets/set-138_wuv4ap.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088695/sets/set-139_kzsgxi.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088701/sets/set-140_jc3cjh.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088706/sets/set-141_qje5bg.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088711/sets/set-142_g5dqde.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088716/sets/set-143_okerts.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088721/sets/set-144_ch3d6a.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088726/sets/set-145_bpjgci.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088129/sets/set-146_cw9xdc.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624907/sets/set-147_ywxmoj.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624907/sets/set-149_nt9pnx.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624906/sets/set-150_ckgld1.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624906/sets/set-160_ip5o9l.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624906/sets/set-157_fbmpnb.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624906/sets/set-158_ucmf8x.jpg"
        alt="Wedding stage set"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624905/sets/set-151_ovrudh.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606625576/sets/set-155_rdyvuk.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606625575/sets/set-152_vpslx8.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606625576/sets/set-153_r38irn.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606625574/sets/set-154_ukdgwy.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088592/sets/set-115_mgskxi.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088596/sets/set-116_fsigni.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088599/sets/set-118_idfjzc.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088604/sets/set-119_kyriex.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088608/sets/set-120_qny5d8.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088616/sets/set-122_d9mnfr.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088620/sets/set-123_j4gq03.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088624/sets/set-124_fwppzr.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624900/sets/set-148_khtxfl.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606624900/sets/set-159_ynjam4.jpg"
        alt="Wedding stage set"
        className="gallery-picture-v"
      />
    </GalleryLayout>
  )
}

export default Decorations
